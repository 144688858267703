a {
  color: #a0ae61;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-content {
  background-color: #e9ecef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-content .canvas-container {
  display: flex
}

.App-content button {
  cursor: pointer
}

.App-content .canvas-container button:disabled {
  cursor: not-allowed;
}

.App-content .canvas-container canvas {
  background-color: white
}

.App-content .cof-container {
  width: 30%;
  margin: 0 auto;
}

.App-content .cof-container img {
  width: 100%
}

.App-content .toggle-controls-container {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: grey;
  padding: 0.5rem;
  color: black;
}

.App-content .toggle-controls-container label {
  margin-right: 1rem, 
}

.App-content .toggle-controls-container p {
  align-self: center;
  color: white;
  cursor: pointer;
  margin: 0;
}

.App-content .toggle-controls-container button {
  align-self: center;
  padding: 0.5rem;
  margin: 0.25rem;
}

.App-link {
  color: #61dafb;
}

.App-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
}

.App-nav-list {
  margin: 0;
  padding: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  width: 100%;
  border-bottom: 1px solid #282c34;
}

.App-nav-list li {
  margin: 0 1rem;
}

.flx {
  display: flex;
}

.code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    color: #282c34;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
